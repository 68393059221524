import React from 'react'
import styled, {css} from "styled-components"
import { font, color, device } from "../../layout/global"
import { Link } from 'gatsby'
import SubmitButton from '../SubmitButton'

const VideoDetail = ({ src, _title, id, positionCenter, titleWidth, textWidth, text, text1, text2, buttonLink, buttonText}) => {

  return (

    <Wrapper 
      className='container' 
      positionCenter={positionCenter} 
      titleWidth={titleWidth} 
      text={text} 
      textWidth={textWidth} 
      buttonLink={buttonLink} 
      buttonText={buttonText} 
      id={id}
    >
        <video className="video" autoPlay playsInline muted loop>
          <source src={src} type="video/ogg"></source>
        </video>

        <div className='text-block'>
          <h1>{_title}</h1>
          <span className='p-container'>
            <p>{text}{text1}<br/>{text2}</p>
          </span>
        </div>

        <Link to={buttonLink} className='link-button'>
          <SubmitButton
            text={buttonText}
          />
        </Link>

        <div className='overlay'></div>      
    </Wrapper>
  )
}

export default VideoDetail

const Wrapper = styled.section`
  background-color: #000;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  position:relative;
  max-height: 100vh !important;
  height: 100vh !important;
  
  .video {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
  }

  .overlay{
    position: absolute;
    top:0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }

  .text-block{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width:100%;
    padding:0 25px;
  }

  h1{
    ${font.robotoBold};
    letter-spacing: 1px;
    line-height: 1;
    color:#fff;
    text-align:left;
    width: ${props => props.titleWidth && props.titleWidth};

    @media ${device.mobileP}{
      font-size:3rem;
    }

    @media ${device.desktop}{
      font-size: 6rem;
    }

    & span {
          color: ${color.red};
    }

    &:before{
      content: '';
      display: ${props => props.positionCenter ? 'none' : 'block'};
      position: absolute;
      width: 60px;
      height: 5px;
      background-color: ${color.white};
      bottom: ${props => props.text ? '50%' : '-30%'};
    }
  }

  .p-container{
    ${font.roboto400};
    color: ${color.white};

    @media ${device.mobileP}{
      font-size:1.6rem;
    }

    @media ${device.desktop}{
      font-size: 2rem;
    }
  }

  .link-button{
    position: absolute;
    z-index: 1;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -80%);

    button{
      background:#FFFFFF0D;
      color: ${color.white};
    }
  }

  ${props => props.positionCenter && css`     
      .p-container{
        display:block;
        margin: 50px auto;
        text-align:center;

        p{
          margin: 60px 0 0 0
        }
      }

      h1{
        text-align:center;
      }
    }`
  }
`